import { FC, useEffect, useRef, useState } from 'react'
import styles from './AutopilotHero.module.scss'
import { AutopilotHeroProps, BenefitProps } from './types'
import { getDefaultBenefits } from './utils'
import { Icon } from 'components/Phantom/Icon'
import * as THREE from 'three'
import { Color, InstancedBufferAttribute, InstancedMesh, Object3D, PerspectiveCamera, Scene, ShaderMaterial, SphereGeometry, WebGLRenderer } from 'three'
import { map } from 'components/_utils/mathUtils'
import { BlendFunction, EffectComposer, EffectPass, RenderPass, SelectiveBloomEffect } from 'postprocessing'
import { useGSAP } from '@gsap/react'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

import gsap from 'gsap'
import { isWebGLAvailable } from 'components/_utils/threeUtils'

export const AutopilotHero: FC<AutopilotHeroProps> = (props) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const cameraRef = useRef<PerspectiveCamera>(null)

	const [containerVisible, setContainerVisible] = useState(false)

	const [threeLoaded, setThreeLoaded] = useState(false)

	const { benefits = getDefaultBenefits() } = props

	useGSAP(() => {
		if (threeLoaded) {
			gsap.registerPlugin(ScrollTrigger)
			gsap.to(cameraRef.current.position, {
				z: 15,
				scrollTrigger: {
					trigger: containerRef.current,
					scrub: 1,
					start: 'top top',
					end: `+=${window.innerHeight}px`,
				},
			})

			gsap.to('#autopilot-canvas', {
				filter: 'blur(50px)',
				ease: 'power1.out',
				scrollTrigger: {
					trigger: containerRef.current,
					scrub: 1,
					start: '500 top',
					end: `+=${window.innerHeight}px`,
				},
			})

			gsap.to('#autopilot-hero', {
				y: 100,
				opacity: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					scrub: true,
					start: 'top top',
					end: `+=${window.innerHeight / 2}px`,
				},
			})

			gsap.from('#autopilot-blueprint', {
				opacity: 0,
				y: 100,
				ease: 'power1.out',
				duration: 1,
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					toggleActions: 'restart none none reverse',
				},
			})

			gsap.from('#autopilot-benefits li', {
				opacity: 0,
				delay: 0.5,
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					toggleActions: 'restart none none reverse',
				},
			})

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					end: 'bottom center',
					scrub: 2,
				},
			})

			gsap.utils.toArray('#autopilot-benefits li').forEach((layer: HTMLElement, i) => {
				const depth = ((i + 2) % (benefits.length / 2)) + 1 + i / 2
				const movement = -(100 * depth)
				tl.to(layer, { y: movement, ease: 'none' }, 0)
			})
		}
	}, [threeLoaded])

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setContainerVisible(entry.isIntersecting)
			})
		})

		observer.observe(containerRef.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	return (
		<div
			className={styles.container}
			ref={containerRef}
		>
			<div className={styles.canvas_rails}>
				<div
					className={styles.canvas_container}
					style={{
						display: containerVisible ? 'block' : 'none',
					}}
				>
					<AutopilotBall
						setThreeLoaded={(camera) => {
							setThreeLoaded(true)
							cameraRef.current = camera
						}}
					/>
				</div>
			</div>
			<section
				className={styles.hero}
				id={'autopilot-hero'}
			>
				<header>
					<p>Autopilot</p>
					<h2>The intelligence behind the Pod</h2>
				</header>
			</section>
			<section
				className={styles.blueprint}
				id={'autopilot-blueprint'}
			>
				<header>
					<h2>Autopilot creates an individual blueprint of you</h2>
					<p>Everyone sleeps differently. Autopilot looks at all your different factors to create a unique blueprint of your sleep needs.</p>
				</header>
				<ul
					className={styles.benefits}
					id={'autopilot-benefits'}
				>
					{benefits.map((benefit, i) => (
						<li key={benefit.text}>
							<Benefit
								{...benefit}
								key={i}
							/>
						</li>
					))}
				</ul>
			</section>
		</div>
	)
}

export const AutopilotBall = (props: { setThreeLoaded?: (camera: PerspectiveCamera) => void; cameraDistance?: number; numSpheres?: number; noBloom?: boolean }) => {
	const canvasRef = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		const canvas = canvasRef.current
		if (!canvas) return () => {}
		if (!isWebGLAvailable()) return () => {}

		const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth
		const parentHeight = canvas.parentElement?.clientHeight || window.innerHeight

		const scene = new Scene()
		const camera = new PerspectiveCamera(75, parentWidth / parentHeight, 0.1, 100)
		camera.position.z = props.cameraDistance || 7

		scene.add(camera)

		const renderer = new WebGLRenderer({ canvas, powerPreference: 'high-performance', antialias: false, stencil: false, depth: false })
		renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2))
		renderer.setSize(parentWidth, parentHeight)
		renderer.setClearColor(0x000000, 0)
		renderer.toneMapping = THREE.ReinhardToneMapping

		const resizeCanvas = () => {
			const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth
			const parentHeight = canvas.parentElement?.clientHeight || window.innerHeight
			camera.aspect = parentWidth / parentHeight
			camera.updateProjectionMatrix()
			renderer.setSize(parentWidth, parentHeight)
		}

		window.addEventListener('resize', resizeCanvas)

		const numSpheres = props.numSpheres || 1000
		const ballRadius = 6
		const sphereRadius = 0.05
		const { colors, instancedMesh } = createSphereInstances(numSpheres, ballRadius, sphereRadius)

		instancedMesh.geometry.setAttribute('instanceColor', new InstancedBufferAttribute(colors, 3))
		scene.add(instancedMesh)

		const composer = new EffectComposer(renderer)

		const renderPass = new RenderPass(scene, camera)
		composer.addPass(renderPass)

		const bloomEffect = new SelectiveBloomEffect(scene, camera, {
			blendFunction: BlendFunction.ADD,
			mipmapBlur: true,
			luminanceThreshold: 0,
			luminanceSmoothing: 0.2,
			intensity: 5,
		})
		bloomEffect.inverted = true
		bloomEffect.dithering = true

		const bloomPass = new EffectPass(camera, bloomEffect)

		if (!props.noBloom) {
			composer.addPass(bloomPass)
		}

		let animationFrameId: number

		let lastTime = 0

		// Animation loop
		const animate = (time: number) => {
			animationFrameId = requestAnimationFrame(animate)
			const elapsedTime = time - lastTime
			lastTime = time

			const elapsedTimeInSeconds = elapsedTime * 0.001

			instancedMesh.rotation.y += elapsedTimeInSeconds * 0.15
			instancedMesh.rotation.x += elapsedTimeInSeconds * 0.05

			composer.render()
		}

		animate(performance.now())

		if (props.setThreeLoaded) {
			props.setThreeLoaded(camera)
		}

		// Cleanup function
		return () => {
			window.removeEventListener('resize', resizeCanvas)

			cancelAnimationFrame(animationFrameId)

			renderer.dispose()
		}
	}, [props.cameraDistance, props.numSpheres])

	return (
		<canvas
			id={'autopilot-canvas'}
			className={styles.canvas}
			ref={canvasRef}
		/>
	)
}

const Benefit: FC<BenefitProps> = (props) => {
	return (
		<div className={styles.benefit}>
			<Icon
				name={props.icon}
				color={'white'}
				size={24}
			/>
			<p>{props.text}</p>
		</div>
	)
}

const material = new ShaderMaterial({
	vertexShader: `
        attribute vec3 instanceColor;
        varying vec3 vInstanceColor;

        void main() {
          vInstanceColor = instanceColor;
          gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);
        }
      `,
	fragmentShader: `
        varying vec3 vInstanceColor;

        void main() {
          gl_FragColor = vec4(vInstanceColor, 1.0);
        }
      `,
})

const createSphereInstances = (numInstances: number, ballRadius: number, sphereRadius: number) => {
	const colors = new Float32Array(numInstances * 3) // RGB for each instance
	const sphereGeometry = new SphereGeometry(sphereRadius, 16, 16) // Small sphere geometry
	const instancedMesh = new InstancedMesh(sphereGeometry, material, numInstances)

	const temp = new Object3D()
	for (let i = 0; i < numInstances; i++) {
		const phi = Math.acos(2 * Math.random() - 1)
		const theta = Math.random() * 2 * Math.PI

		const x = Math.sin(phi) * Math.cos(theta)
		const y = Math.sin(phi) * Math.sin(theta)
		const z = Math.cos(phi)

		temp.position.set(x, y, z)
		temp.position.multiplyScalar(ballRadius)
		temp.updateMatrix()
		instancedMesh.setMatrixAt(i, temp.matrix)

		const color = new Color()
		color.setHSL(map(y, -1, 1, 0.6, 0.8), 0.8, map(x, 0, 1, 0.5, 0.7))
		colors[i * 3] = color.r
		colors[i * 3 + 1] = color.g
		colors[i * 3 + 2] = color.b
	}

	return { colors, instancedMesh }
}
