import { FC, useRef, useState } from 'react'
import { TempDialProps } from 'components/Phantom/TempDial/TempDial.types'
import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { TempDial } from 'components/Phantom/TempDial/TempDial'

export const TempDialAnimateUp: FC<TempDialProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null)
	const [timeline, setTimeline] = useState<gsap.core.Timeline | null>(null)

	const handleTimelineReady = (timeline: gsap.core.Timeline) => {
		setTimeline(timeline)
	}

	useGSAP(
		() => {
			gsap.registerPlugin(ScrollTrigger)
			if (!timeline) return

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: ref.current,
					start: 'top 80%',
					end: '+=300px',
					scrub: 1,
				},
			})

			tl.fromTo(
				ref.current,
				{
					opacity: 0,
					backdropFilter: 'blur(0px)',
				},
				{
					opacity: 1,
					backdropFilter: 'blur(12px)',
					duration: 1,
				}
			)

			tl.fromTo(
				ref.current,
				{
					y: 100,
				},
				{ y: 0, duration: 2 },
				0
			)
			tl.add(timeline, 0)
		},
		{
			dependencies: [timeline],
			revertOnUpdate: true,
		}
	)

	return (
		<TempDial
			{...props}
			trigger={true} // The animation needs to be playable in order to be puppeteered by ScrollTrigger
			duration={2}
			ease={'none'} // Turn ease off since we're using ScrollTrigger
			ref={ref}
			onTimelineReady={handleTimelineReady}
		/>
	)
}
