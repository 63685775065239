import { FC, useEffect, useRef, useState } from 'react'
import styles from './NewStandard.module.scss'
import { NewStandardProps } from './types'
import { getDefaultBenefits } from './utils'
import { Icon } from 'components/Phantom/Icon'
import { VideoGif } from 'components/basic/VideoGif'
import { Button } from 'components/Phantom/Button'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

export const NewStandardConnected = observer(() => {
	const { metricRegion } = useRootStore().settingsStore
	return <NewStandard metric={metricRegion} />
})

export const NewStandard: FC<NewStandardProps> = (props) => {
	const { benefits = getDefaultBenefits(), metric = false } = props
	const [videoVisible, setVideoVisible] = useState(false)

	const videoSource = metric
		? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-standard-celsius.mp4'
		: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/GE-2342+Pod+4+Ultra.mp4'

	return (
		<div className={styles.container}>
			<header>
				<p>New</p>
				<h2>Pod 4 Ultra is the new standard in sleep</h2>
			</header>

			<div className={styles.video_container}>
				<VideoGif src={videoSource} />
			</div>

			<ul className={styles.benefits}>
				{benefits.map((benefit, index) => (
					<li key={index}>
						<Icon
							name={benefit.icon}
							color={'black'}
						/>
						<p>{benefit.text}</p>
					</li>
				))}
			</ul>
			<div className={styles.cta_container}>
				<Button.VideoGrey
					onClick={() => setVideoVisible(true)}
					id={'new-standard-watch-video'}
				>
					Watch the video
				</Button.VideoGrey>
			</div>

			<FullScreenModal
				closeAction={() => {
					setVideoVisible(false)
				}}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<VideoPlayer
					metric={metric}
					forcePlaying={videoVisible}
				/>
			</FullScreenModal>
		</div>
	)
}

export const VideoPlayer = (props: { forcePlaying: boolean; metric: boolean }) => {
	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (props.forcePlaying) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [props.forcePlaying])

	return (
		<div className={styles.videoContainer}>
			<video
				ref={videoRef}
				src={
					props.metric
						? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod_4_launch_metric_subtitles.mp4'
						: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pod+4+launch+with+subtitles.mp4'
				}
				preload={'none'}
				autoPlay={false}
				loop={false}
				controls
			/>
		</div>
	)
}
