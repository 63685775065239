import { FC, useEffect, useRef, useState } from 'react'
import { AutopilotBrieferProps, CardComponentProps, CardHintProps, CardProps } from './types'
import styles from './AutopilotBriefer.module.scss'
import { getDefaultCards } from 'components/Phantom/_sections/AutopilotBriefer/utils'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Source } from 'components/basic/Img'
import { LinkWithArrow } from 'components/basic/LinkWithArrow'
import { generateID } from 'components/_utils/idUtils'
import { AutopilotBall } from 'components/Phantom/_sections/AutopilotHero'

export const AutopilotBrieferStoreConnected = observer(() => {
	const { settingsStore } = useRootStore()
	return <AutopilotBriefer metric={settingsStore.metricRegion} />
})

export const AutopilotBrieferCTAStoreConnected = observer(() => {
	const { settingsStore } = useRootStore()
	return (
		<AutopilotBriefer
			metric={settingsStore.metricRegion}
			cta={{ text: 'Learn more', href: '/autopilot/' }}
		/>
	)
})

const heroSources: Source[] = [
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/autopilot-briefer-header-desktop.png',
		mediaQuery: '(min-width: 1023px)',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/autopilot-briefer-header-mobile.png',
		mediaQuery: '(max-width: 1023px)',
	},
]

export const AutopilotBriefer: FC<AutopilotBrieferProps> = (props) => {
	const { cards = getDefaultCards(props.metric), title = 'Put your sleep on Autopilot', metric = false } = props
	const [componentVisible, setComponentVisible] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const resizeHandler = () => {
			const shader = document.getElementById('autopilot-shader')
			const intro = document.getElementById('autopilot-intro')

			if (window.innerWidth < 1023) {
				intro.style.marginTop = `${shader?.offsetHeight || 0}px`
			} else {
				intro.style.marginTop = '0px'
			}
		}

		resizeHandler()

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setComponentVisible(entry.isIntersecting)
			})
		})

		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		window.addEventListener('resize', resizeHandler)
		return () => {
			observer.disconnect()
			window.removeEventListener('resize', resizeHandler)
		}
	}, [])

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<article className={styles.autopilot_intro_container}>
				<div
					className={styles.autopilot_intro}
					id={`autopilot-intro`}
				>
					<h3 className={styles.eyebrow}>The intelligence behind the Pod</h3>
					<header className={styles.header}>
						<h2>{title}</h2>
					</header>
					<p className={styles.autopilot_body}>
						Experience a new era of sleep with Autopilot. It never stops learning about what you need for the best night of sleep, based on your age, biological sex, sleep history, environmental
						temperature, and more.
					</p>
					{props.cta && (
						<div className={styles.cta_container}>
							<LinkWithArrow
								id={'autopilot-briefer-cta'}
								href={props.cta.href}
								className={styles.cta}
								color="#0038FF"
							>
								{props.cta.text}
							</LinkWithArrow>
						</div>
					)}
				</div>

				<div
					className={styles.autopilot_shader}
					id={`autopilot-shader`}
				>
					{componentVisible && (
						<AutopilotBall
							cameraDistance={13}
							numSpheres={2000}
							noBloom
						/>
					)}
				</div>
			</article>

			<ul className={styles.card_list}>
				{cards.map((card) => {
					return (
						<li
							key={card.title}
							className={styles.card_wrapper}
						>
							<AutopilotBrieferCard
								{...card}
								metric={metric}
							/>
						</li>
					)
				})}
			</ul>
		</section>
	)
}

const AutopilotBrieferCard: FC<CardProps> = (props) => {
	return (
		<article className={styles.card}>
			<header className={styles.card_header}>
				<p className={styles.card_supertitle}>{props.supertitle}</p>
				<h3 className={styles.card_title}>
					{props.title} {props.badge && <span className={styles.card_new_badge}>{props.badge}</span>}
				</h3>
				<p className={styles.card_subtitle}>{props.subtitle}</p>
				<LinkWithArrow
					id={`autopilot_cta_${generateID(props.supertitle)}`}
					href={props.cta.href}
					className={styles.cta}
					color="#0038FF"
				>
					{props.cta.text}
				</LinkWithArrow>
				<div className={styles.desktop_cardhint_wrapper}>
					<CardHint {...props.hint} />
				</div>
				{props.footnote && <div className={styles.footnote_desktop}>{props.footnote}</div>}
			</header>

			<CardComponent
				cardComponent={props.cardComponent}
				metric={props.metric}
				footnote={props.footnote}
			/>
			<div className={styles.mobile_cardhint_wrapper}>
				<CardHint {...props.hint} />
			</div>
		</article>
	)
}

const CardComponent: FC<CardComponentProps> = (props) => {
	const Component = props.cardComponent

	if (!Component) {
		return null
	}

	return (
		<div className={styles.card_component_container}>
			<Component metric={props.metric} />
			{props.footnote && <div className={styles.footnote_mobile}>{props.footnote}</div>}
		</div>
	)
}

const CardHint: FC<CardHintProps> = (props) => {
	if (!props || !props.text) {
		return null
	}

	return (
		<div className={styles.card_hint_wrapper}>
			<div className={styles.card_hint}>
				{/* <Icon
					name={props.icon}
					size={'24px'}
					color={'black'}
					className={styles.card_icon}
				/> */}
				<p>{props.text}</p>
			</div>
		</div>
	)
}
